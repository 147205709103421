<template>
  <div class="pa-3">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-dialog
        v-model="showDetail"
        :max-width="
          editedItem.bylist == '1' ||
          (editedItem.bylist == '0' && depot.NbRack > 0)
            ? '1600px'
            : '700px'
        "
        persistent
        transition="dialog-top-transition"
        @keydown.esc="close"
      >
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitle }}</span>
            <v-spacer></v-spacer>
            <v-layout row justify-center align-center v-if="progress">
              <v-progress-circular
                indeterminate
                :size="30"
                :width="7"
                color="purple"
              ></v-progress-circular>
            </v-layout>

            <v-btn
              color="blue darken-1"
              @click="save"
              :disabled="save_disable"
              class="mx-2"
              small
            >
              Enregistrer
            </v-btn>
            <v-btn color="blue darken-1" @click.stop="close" small>
              Fermer
            </v-btn>
          </v-card-title>

          <v-card-text>
            <v-container fluid>
              <v-row dense>
                <v-col cols="12" sm="6" md="6">
                  <v-autocomplete
                    ref="mvmfrom"
                    v-model="editedItem.mvm_from"
                    item-text="label"
                    item-value="id"
                    :items="depots_from"
                    label="Depot Source"
                    :rules="[
                      (v) => !!v || 'Depot obligatoire',
                      (v) =>
                        ligne_exist(
                          editedItem.article_id,
                          v,
                          editedItem.depot_id
                        ) == false || 'Ligne existe!',
                    ]"
                    dense
                    @change="depot_change"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="12">
                  <v-autocomplete
                    v-model="editedItem.produit_id"
                    item-text="product_name"
                    :item-value="'produit_id'"
                    :items="products"
                    :filter="customFilter"
                    label="Produit"
                    :rules="[(v) => !!v || 'Produit obligatoire']"
                    dense
                    :loading="loading"
                    @change="product_change"
                  >
                    <template v-slot:selection="{ item }">
                      <!-- HTML that describe how select should render selected items -->
                      {{
                        (item.product_code ? item.product_code + "-" : "") +
                        item.product_name
                      }}
                    </template>
                    <template slot="item" slot-scope="data">
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="data.item.product_name"
                        ></v-list-item-title>
                        <v-list-item-title
                          v-html="'Code : ' + data.item.product_code"
                        ></v-list-item-title>
                        <v-list-item-subtitle
                          v-html="
                            'Quantite : ' +
                            data.item.stock_cmpt +
                            (data.item.unit ? ' ' + data.item.unit : '')
                          "
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </v-col>

                <v-col cols="12" sm="6" md="12">
                  <v-autocomplete
                    v-model="editedItem.article_id"
                    item-text="article_name"
                    :item-value="'article_id'"
                    :items="articles"
                    label="Article"
                    :rules="[
                      (v) => !!v || 'Article obligatoire',
                      (v) =>
                        ligne_exist(
                          v,
                          editedItem.mvm_from,
                          editedItem.depot_id
                        ) == false || 'Ligne existe!',
                    ]"
                    dense
                    :loading="loading"
                    @change="article_change"
                  >
                    <template v-slot:selection="{ item }">
                      <!-- HTML that describe how select should render selected items -->
                      {{
                        (item.code ? item.code + "-" : "") + item.article_name
                      }}
                    </template>
                    <template slot="item" slot-scope="data">
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="data.item.article_name"
                        ></v-list-item-title>
                        <v-list-item-title
                          v-html="'Code : ' + data.item.code"
                        ></v-list-item-title>
                        <v-list-item-subtitle
                          v-html="
                            'Ref. : ' +
                            (data.item.ref_ext ? data.item.ref_ext : '')
                          "
                        ></v-list-item-subtitle>
                        <v-list-item-subtitle
                          v-html="
                            'Fournisseur : ' +
                            (data.item.tier_name ? data.item.tier_name : '')
                          "
                        ></v-list-item-subtitle>
                        <v-list-item-subtitle
                          v-html="
                            'Quantite : ' +
                            (data.item.stock_cmpt ? data.item.stock_cmpt : 0) +
                            (data.item.unit ? ' ' + data.item.unit : '')
                          "
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </v-col>

                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    autocomplete="off"
                    ref="qte"
                    class="inputPrice"
                    type="number"
                    dense
                    v-model.number="editedItem.qte"
                    :label="
                      'Quantité' +
                      (editedItem.unit ? ' (' + editedItem.unit + ')' : '')
                    "
                    :rules="[
                      (v) => !!v || 'Quantité obligatoire',
                      (v) => v > 0 || 'Valeur incorrecte ',
                      (v) =>
                        parseFloat(v) <= parseFloat(qte_max) ||
                        'Erreur Stock ' + qte_max,
                    ]"
                    hide-spin-buttons
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                  v-if="
                    $store.state.auth.includes('01027') || $store.state.isadmin
                  "
                >
                  <v-text-field
                    autocomplete="off"
                    ref="pmp"
                    class="inputPrice"
                    type="number"
                    dense
                    v-model.number="editedItem.pmp"
                    readonly
                    label="PMP"
                    hide-spin-buttons
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <v-autocomplete
                    ref="dt"
                    v-model="editedItem.depot_id"
                    item-text="label"
                    item-value="id"
                    :items="depots_to"
                    label="Depot de Destination"
                    @change="dest_change"
                    :rules="[
                      (v) => !!v || 'Depot obligatoire',
                      (v) =>
                        v != editedItem.mvm_from ||
                        (v == editedItem.mvm_from && depot.NbRack > 0) ||
                        'Même Depot!',
                      (v) =>
                        ligne_exist(
                          editedItem.article_id,
                          editedItem.mvm_from,
                          v
                        ) == false || 'Ligne existe!',
                    ]"
                    dense
                    @keydown.enter="save"
                    :disabled="save_disable"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="1" md="12">
                  <v-text-field
                    autocomplete="off"
                    ref="comment"
                    dense
                    v-model="editedItem.comment"
                    label="Commentaire"
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="1"
                  md="6"
                  v-if="
                    (editedItem.bylist == '1' ||
                      (editedItem.bylist == '0' && depot.NbRack > 0)) &&
                    editedItem.article_id > 0
                  "
                >
                  <v-row>
                    <v-col cols="12" sm="1" md="11">
                      <listitems
                        :list="flist"
                        :headers="
                          editedItem.bylist == '1'
                            ? dispo_headers
                            : boxheaders_d
                        "
                        :title="depot.label"
                        :master="true"
                        :add="false"
                        :del="false"
                        :Update="false"
                        :showedit="true"
                        :Total="true"
                        :print="true"
                        :exp_excel="true"
                        :imp_excel="false"
                        :key="kflist"
                        :multiple="true"
                        @selected_rows="selected_left_change"
                        :laoding="productload"
                        :ipg="5"
                      >
                      </listitems>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="1"
                      md="1"
                      v-if="editedItem.mvm_from && editedItem.depot_id"
                    >
                      <v-btn
                        fab
                        color="blue darken-1"
                        @click="add_product"
                        class="my-2"
                        small
                        :disabled="selected_left.length == 0"
                      >
                        >
                      </v-btn>

                      <v-btn
                        fab
                        color="blue darken-1"
                        @click="del_product"
                        class="my-2"
                        small
                        :disabled="selected_right.length == 0"
                        v-if="editedItem.mvm_from && editedItem.depot_id"
                      >
                        <span v-html="'<'"></span>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col
                  cols="12"
                  sm="4"
                  md="6"
                  v-if="
                    (editedItem.bylist == '1' ||
                      (editedItem.bylist == '0' && depot.NbRack > 0)) &&
                    editedItem.depot_id
                  "
                >
                  <listitems
                    :list="form_list"
                    :headers="
                      editedItem.bylist == '1' ? article_headers : boxheaders_s
                    "
                    :title="dest.label"
                    :master="true"
                    :add="false"
                    :del="false"
                    :Update="true"
                    :showedit="true"
                    :showstd="true"
                    :Total="true"
                    :print="true"
                    :exp_excel="true"
                    :imp_excel="false"
                    :key="frml"
                    :multiple="true"
                    @selected_rows="selected_right_change"
                    @add="add_item"
                    @delete="del_item"
                    :laoding="listload"
                    :ipg="5"
                  >
                  </listitems>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions> </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>

    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
  </div>
</template>

<script>
import MVM_DETAIL_UPDATE from "../graphql/Mouvment/MVM_DETAIL_UPDATE.gql";
//import DEPOT_ARTICLES from "../graphql/Depot/DEPOT_ARTICLES.gql";
import ALLARTICLES_DEPOT from "../graphql/Depot/ALLARTICLES_DEPOT.gql";
import PRODUCT_LIST from "../graphql/Product/PRODUCT_LIST.gql";

export default {
  components: {
    listitems: () => import("./ListItems.vue"),
  },
  name: "mvmtransfertform",
  props: {
    mvm_type: Number,
    item: Object,
    items: Array,
    attributes: Array,
    showForm: Boolean,
    units: Array,
    boxs: Array,
  },
  data: () => ({
    progress: false,
    valid: true,
    save_disable: false,
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
    defaultItem: {},
    editedItem: {},
    product: {},
    qte_max: 0,
    articles: [],
    products: [],
    loading: false,
    productload: false,
    precision: 2,
    form_list: [],
    prdslist: [],
    selected_left: [],
    selected_right: [],
    stock_boxs: [],
    kflist: 100,
    frml: 600,
    listload: false,
    depot: {},
    dest: {},

    dispo_headers: [
      {
        text: "No",
        value: "no",
        selected: true,
      },
      {
        text: "Index",
        value: "code",
        selected: true,
      },
      {
        text: "Code",
        value: "ref",
        selected: true,
      },
      {
        text: "Quantité",
        value: "qte",
        total: "qte",
        slot: "cur",
        align: "end",
        selected: true,
      },
      {
        text: "Date de péremption",
        value: "expire_date",
        selected: true,
        hiden: true,
        slot: "date",
      },
      {
        text: "Origine",
        value: "origin",
        selected: true,
      },
      {
        text: "Box",
        value: "box",
        selected: true,
        hiden: true,
      },

      {
        text: "Comment",
        value: "comment",
        selected: true,
        edit: true,
        type: "text",
        sm: 12,
        md: 12,
      },
    ],
    article_headers: [
      {
        text: "No",
        value: "no",
        selected: true,
        totlib: true,
      },
      {
        text: "Index",
        value: "code",
        selected: true,
      },
      {
        text: "Code",
        value: "ref",
        selected: true,
        hiden: true,
      },

      {
        text: "Quantité",
        value: "qte",
        total: "qte",
        slot: "href22",
        align: "end",
        selected: true,
      },
      {
        text: "Date de péremption",
        value: "expire_date",
        selected: true,
        hiden: true,
        slot: "date",
      },
      {
        text: "Origine",
        value: "origin",
        selected: true,
      },
      {
        text: "Box",
        value: "transf_box_id",
        slot: "simple-select",
        selected: true,
        hiden: true,
        edit: false,
        type: "autocomplete",
        item_value: "id",
        item_text: "box",
        subtitle1: "rack",
        subtitle1_label: "Rack",
        subtitle2: "stock_cmpt",
        subtitle2_label: "Stock",
        subtitle3: "nbarticle",
        subtitle3_label: "Nb : ",
        select_list: [],
        show_list: [],
        default: "",
        sm: 6,
        md: 6,
        rules: [(v) => !!v || "Box obligatoire"],
      },
      {
        text: "Comment",
        value: "comment",
        selected: true,
        edit: true,
        type: "text",
        sm: 12,
        md: 12,
      },
    ],
    colisheaders2: [
      {
        text: "No",
        value: "no",
        selected: true,
        totlib: true,
      },
      {
        text: "Index",
        value: "code",
        selected: true,
      },
      {
        text: "Code",
        value: "ref",
        hiden: true,
        selected: true,
      },
      {
        text: "Quantité",
        value: "qte",
        total: "qte",
        slot: "cur",
        align: "end",
        selected: true,
        edit: true,
        type: "number",
        dec: 2,
        sm: 3,
        md: 3,
        rules: [(v) => !!v || "Quantité obligatoire"],
      },
      {
        text: "Date de péremption",
        value: "expire_date",
        selected: true,
        slot: "date",
        hiden: true,
      },
      {
        text: "Origine",
        value: "origin",
        selected: true,
      },
      {
        text: "Comment",
        value: "comment",
        selected: true,
        edit: true,
        type: "text",
        sm: 12,
        md: 12,
      },
    ],
    boxheaders_d: [
      {
        text: "No",
        value: "no",
        selected: true,
        totlib: true,
      },
      {
        text: "Rack",
        value: "rack",
        selected: true,
      },
      {
        text: "Box",
        value: "box",
        selected: true,
      },
      {
        text: "Quantité",
        value: "qte",
        total: "qte",
        slot: "cur",
        align: "end",
        selected: true,
      },
    ],
    boxheaders_s: [
      {
        text: "No",
        value: "no",
        selected: true,
        totlib: true,
      },

      {
        text: "Box",
        value: "transf_box_id",
        slot: "simple-select",
        selected: true,
        hiden: true,
        edit: false,
        type: "autocomplete",
        item_value: "id",
        item_text: "box",
        subtitle1: "rack",
        subtitle1_label: "Rack",
        subtitle2: "stock_cmpt",
        subtitle2_label: "Stock",
        subtitle3: "nbarticle",
        subtitle3_label: "Nb : ",
        select_list: [],
        show_list: [],
        default: "",
        sm: 6,
        md: 6,
        rules: [(v) => !!v || "Box obligatoire"],
      },
      {
        text: "Quantité",
        value: "qte",
        total: "qte",
        slot: "href22",
        align: "end",
        selected: true,
        edit: true,
        type: "qte",
        dec: 2,
        sm: 3,
        md: 3,
        rules: [(v) => !!v || "Quantité obligatoire"],
      },
    ],
    adding: false,
    deleting: false,
    mvm_from: "",
  }),

  computed: {
    boxs_list() {
      let list = [];
      if (this.boxs)
        list = this.boxs.filter(
          (elm) => elm.depot_id == this.editedItem.depot_id
        );
      return list;
    },
    flist() {
      let l = [];
      if (this.editedItem.bylist == "1") {
        l = this.prdslist.filter(
          (elm) => !this.form_list.map((elm3) => elm3.id).includes(elm.id)
        );
      } else {
        l = this.stock_boxs.filter(
          (elm) =>
            !this.form_list.map((elm3) => elm3.box_id).includes(elm.box_id)
        );
      }

      return l;
    },
    attributs_list() {
      return this.attributes.filter((elm) => elm.type_attr == "colis");
    },
    depots_from() {
      return this.$store.state.depots.filter(
        (elm) =>
          elm.ChildCount == 0 && elm.type_id == (this.mvm_type == 5 ? 2 : 3)
      );
    },
    depots_to() {
      return this.$store.state.depots.filter(
        (elm) => elm.ChildCount == 0 && elm.type_id == 2
      );
    },

    sumlist_selected() {
      let list = [];
      list = this.form_list ? this.form_list : [];
      return list
        ? parseFloat(
            list
              .reduce((a, b) => a + (b["qte"] || 0), 0)
              .toFixed(this.precision)
          )
        : 0;
    },
    keyproduct() {
      return this.product.id;
    },
    showDetail() {
      return this.showForm;
    },
    formTitle() {
      if (this.item)
        return this.item.id < 0
          ? "Nouvelle Ligne "
          : "Ligne N° ".concat(parseInt(this.item.index) + 1);
      else return "";
    },
  },
  watch: {
    form_list: {
      handler() {
        if (
          this.editedItem.bylist == "1" ||
          (this.editedItem.bylist == "0" && this.depot.NbRack > 0)
        ) {
          this.editedItem.qte = this.sumlist_selected.toFixed(this.precision);
        }
      },
      deep: true,
    },
  },

  created() {},
  async mounted() {
    this.$refs.form.resetValidation();

    if (this.item) {
      if (this.item.id > 0) {
        this.editedItem.mvm_from = this.item.mvm_from;
        await this.depot_change();

        this.editedItem = Object.assign({}, this.item);
        this.dest_change();
        this.product_change();
        this.article_change();
      } else this.editedItem = Object.assign({}, this.defaultItem);
    }
  },

  methods: {
    add_item() {
      if (this.editedItem.bylist == "0")
        this.boxheaders_s[1].select_list = this.boxs_list.filter(
          (elm) => !this.form_list.map((elm) => elm.box_id).includes(elm.id)
        );
    },
    del_item() {
      this.editedItem.qte = this.sumlist_selected.toFixed(this.precision);
    },
    async load_product_list() {
      //list possible

      this.productload = true;
      let v = {
        article_id: this.editedItem.article_id,
        depot_id: this.editedItem.mvm_from,
        stock_id: 1,
      };
      let r = await this.requette(PRODUCT_LIST, v);
      if (r) this.prdslist = r.product_list;

      this.productload = false;
      this.kflist++;
    },
    async list_mvm() {
      if (this.editedItem.id >= 0) {
        this.listload = true;
        let v = {
          mvm_detail_id: this.editedItem.id,
        };

        let r = await this.requette(PRODUCT_LIST, v);
        if (r) {
          this.form_list = r.product_list;
          this.frml++;
          this.editedItem.qte = this.sumlist_selected.toFixed(this.precision);
          this.listload = false;
        }
      }
    },

    selected_left_change(items) {
      this.selected_left = items;
    },
    selected_right_change(items) {
      this.selected_right = items;
    },
    add_product() {
      if (this.selected_left.length > 0) {
        this.selected_right = [];
        this.selected_left.forEach((element) => {
          element.selected = false;
          this.form_list.unshift(element);
        });
        this.selected_left = [];
        this.editedItem.qte = this.sumlist_selected.toFixed(this.precision);
        this.frml++;
        this.kflist++;
      }
    },

    del_product() {
      if (this.selected_right) {
        this.selected_left = [];
        this.selected_right.forEach((element) => {
          element.selected = false;
          this.form_list.splice(
            this.form_list.findIndex((elm) => elm.id == element.id),
            1
          );
        });
        this.selected_right = [];
        this.editedItem.qte = this.sumlist_selected.toFixed(this.precision);
        this.frml++;
        this.kflist++;
      }
    },
    dest_change() {
      let i = this.depots_to.findIndex(
        (elm) => elm.id == this.editedItem.depot_id
      );
      if (i >= 0) {
        this.dest = this.depots_to[i];
        // boxs_list_to

        this.article_headers[6].text = this.dest.box ? this.dest.box : "Box";
        this.article_headers[6].hiden = this.dest.NbRack == 0;
        this.boxheaders_s[1].hiden = this.dest.NbRack == 0;
        this.boxheaders_s[1].edit = this.dest.NbRack > 0;
        this.article_headers[6].edit = this.dest.NbRack > 0;
        this.article_headers[6].rules = [
          (v) => !!v || this.article_headers[6].text + " obligatoire",
        ];
        this.article_headers[6].subtitle1_label = this.dest.rack
          ? this.dest.rack
          : "Rack";

        this.article_headers[6].select_list = this.boxs_list;
        this.article_headers[6].show_list = this.boxs_list;
        let u = "";
        let z;
        if (this.units)
          z = this.units.findIndex((elm) => elm.id == this.editedItem.fk_unit);
        if (z >= 0) u = "(" + this.units[z].name + ")";
        this.boxheaders_s[1].text = this.dest.box ? this.dest.box : "Box";
        this.boxheaders_s[1].select_list = this.boxs_list.filter(
          (elm) => !this.form_list.map((elm) => elm.box_id).includes(elm.id)
        );
        this.boxheaders_s[1].show_list = this.boxs_list;
        this.boxheaders_s[2].text = "Quantité " + u;
        this.boxheaders_s[2].dec = this.precision;
        if (
          this.editedItem.bylist == "0" &&
          this.dest.NbRack > 0 &&
          this.editedItem.id > 0
        ) {
          this.form_list = this.editedItem.boxs;
          this.frml++;
        }
      }
    },
    async depot_change() {
      this.loading = true;
      this.products = [];
      this.articles = [];
      this.editedItem.produit_id = null;
      this.editedItem.article_id = null;
      this.$refs.form.resetValidation();
      let i = this.depots_from.findIndex(
        (elm) => elm.id == this.editedItem.mvm_from
      );

      if (i >= 0) {
        this.depot = this.depots_from[i];
        this.dispo_headers[6].text = this.depot.box ? this.depot.box : "Box";
        this.dispo_headers[6].hiden = this.depot.NbRack == 0;
        let u = "";
        let z;
        if (this.units)
          z = this.units.findIndex((elm) => elm.id == this.editedItem.fk_unit);
        if (z >= 0) u = "(" + this.units[z].name + ")";

        this.boxheaders_d[1].text = this.depot.rack ? this.depot.rack : "Rack";
        this.boxheaders_d[2].text = this.depot.box ? this.depot.box : "Box";
        this.boxheaders_d[3].text = "Quantité " + u;
        this.boxheaders_d[3].dec = this.precision;
      }
      let v = {
        where: {
          AND: [
            {
              column: "DEPOT_ID",
              value: this.editedItem.mvm_from,
            },
            {
              column: "STOCK_CMPT",
              operator: "GT",
              value: 0,
            },
            {
              column: "LINKCOUNT",
              value: 0,
            },
          ],
        },
      };
      let r = await this.requette(ALLARTICLES_DEPOT, v);
      if (r) {
        this.products = r.allarticles_depot;
      }

      // let v = {
      //   depot_id: [this.editedItem.mvm_from],
      // };
      // let r = await this.requette(DEPOT_ARTICLES, v, true);
      // if (r) this.products = r.depot_articles;
      this.loading = false;
      this.form_list = [];
      this.prdslist = [];
      this.frml++;
      this.kflist++;
    },
    async maj(query, v, refresh) {
      let r;
      this.progress = true;
      await this.$apollo
        .mutate({
          mutation: query,
          variables: v,
        })
        .then((data) => {
          if (refresh) {
            this.$store.dispatch("Changed", true);
            this.$emit("change");
          }
          r = data.data;
          this.progress = false;
        })
        .catch((error) => {
          this.loading = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },
    async requette(query, v) {
      let r;
      await this.$apollo
        .query({
          query: query,
          variables: v,
          fetchPolicy: "no-cache",
        })
        .then((data) => {
          r = data.data;
        })
        .catch((error) => {
          this.loading = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },
    customFilter(item, queryText) {
      let textlist = [];
      let l = ["product_code", "product_name"];

      l.forEach((element) => {
        const text1 = item[element] ? item[element].toLowerCase() : "";
        textlist.push(text1);
      });

      const searchText = queryText.toLowerCase();
      const index = textlist.findIndex((element) => {
        if (element.includes(searchText)) {
          return true;
        }
      });
      return index !== -1;
    },
    async product_change() {
      this.articles = this.products.filter(
        (elm) =>
          elm.produit_id == this.editedItem.produit_id &&
          (!this.items.map((elm) => elm.article_id).includes(elm.id) ||
            elm.id == this.editedItem.article_id)
      );
      if (this.articles.length == 1) {
        this.editedItem.article_id = this.articles[0].article_id;
        this.article_change();
      }
    },
    async article_change() {
      let i = this.articles.findIndex(
        (elm) => elm.article_id == this.editedItem.article_id
      );
      if (i >= 0) {
        this.stock_boxs = this.articles[i].stock_boxs;
        this.kflist++;
        this.editedItem.pmp = this.articles[i].pmp;
        this.editedItem.fk_unit = this.articles[i].fk_unit;
        this.editedItem.ppattributs = this.articles[i].ppattributs;
        this.editedItem.unit_name = this.articles[i].unit_name;
        this.editedItem.unit = this.articles[i].unit;
        this.editedItem.is_expire = this.articles[i].is_expire;
        this.editedItem.product_name = this.articles[i].product_name;
        this.editedItem.bylist = this.articles[i].bylist;

        this.precision = this.articles[i].decimal;

        this.qte_max = this.articles[i].stock_cmpt;
      }

      if (this.editedItem.bylist == "1") {
        this.init_list();
        this.form_list = [];
        this.frml++;

        await this.list_mvm();
        await this.load_product_list();
      }
    },
    init_list() {
      this.dispo_headers[1].hiden =
        this.$store.state.options[0].code_list == "1";
      this.article_headers[1].hiden = this.dispo_headers[1].hiden;
      this.dispo_headers[2].hiden =
        this.$store.state.options[0].code_list == "0";
      this.article_headers[2].hiden = this.dispo_headers[2].hiden;
      this.dispo_headers[4].hiden = this.editedItem.is_expire == "0";
      this.article_headers[4].hiden = this.dispo_headers[4].hiden;
      this.dispo_headers[3].text = this.editedItem.unit
        ? "Qte (" + this.editedItem.unit + ")"
        : "Qte";
      this.article_headers[3].text = this.dispo_headers[3].text;
      this.dispo_headers[3].dec = this.precision;
      this.article_headers[3].dec = this.dispo_headers[3].dec;
      let colis_attributs = [];

      colis_attributs = this.editedItem.ppattributs
        ? this.editedItem.ppattributs.split(",")
        : [];
      for (let index = 0; index < colis_attributs.length; index++) {
        const element = colis_attributs[index];

        let i = this.attributs_list.findIndex((elm) => elm.id == element);
        if (i >= 0) {
          let ls = [];
          this.attributs_list[i].attributvalues.forEach((element) => {
            ls.push(element.name);
          });
          let elm = {
            text: this.attributs_list[i].caption
              ? this.attributs_list[i].caption
              : this.attributs_list[i].name,
            value: "ref" + index,
            selected: true,
            edit: true,
            key: this.attributs_list[i].key,
            type:
              this.attributs_list[i].attributvalues.length > 0
                ? "autocomplete"
                : "text",
            select_list: ls,
            show_list: ls,
            sm: 6,
            md: 6,
          };
          this.dispo_headers.splice(index + 2, 0, elm);
          this.article_headers.splice(index + 2, 0, elm);
        }
      }
    },

    ligne_exist(p, s, d) {
      let i = this.items.filter((elm) => elm.id != this.item.id);
      let e = -1;
      e = i.findIndex(
        (elm) => elm.article_id == p && elm.mvm_from == s && elm.mvm_from == d
      );
      return e >= 0;
    },
    close() {
      this.$emit("close", this.items);
    },
    verifie_attribut() {
      let ok = true;
      let l = this.article_headers.filter(
        (elm) => elm.edit == true && elm.hiden != true
      );
      for (let index = 0; index < this.form_list.length; index++) {
        const element = this.form_list[index];

        for (let i = 0; i < l.length; i++) {
          if (!element[l[i].value] && l[i].value != "comment") {
            this.snackbar_text =
              l[i].text + " manquant! Ligne " + (parseInt(index) + 1);
            this.snackbar_color = "error";
            this.snackbar = true;
            ok = false;
            break;
          }
        }
        if (this.editedItem.is_expire == "1" && !element.expire_date) {
          this.snackbar_text =
            "Date péremption manquante! Ligne " + (parseInt(index) + 1);
          this.snackbar_color = "error";
          this.snackbar = true;
          ok = false;
          break;
        }
      }
      return ok;
    },
    async save() {
      if (this.$refs.form.validate()) {
        // let ok = this.verifie_attribut();
        // if (ok) {
        this.save_disable = true;
        let v;
        let products_list = [];
        let pl = [];
        let boxs = [];
        if (this.editedItem.bylist == "1") {
          this.form_list.forEach((element) => {
            products_list.push({
              fk_produit_list: element.id,
              qte: element.qte,
            });
            pl.push({
              id: element.id,
              ref: element.ref,
              ref0: element.ref0,
              ref1: element.ref1,
              ref2: element.ref2,
              ref3: element.ref3,
              ref4: element.ref4,
              ref5: element.ref5,
              transf_box_id: element.transf_box_id,
            });
          });
        }
        if (this.editedItem.bylist == "0" && this.dest.NbRack > 0) {
          for (let index = 0; index < this.form_list.length; index++) {
            const element = this.form_list[index];
            let elm = {};
            elm.article_id = this.editedItem.article_id;
            elm.box_id = element.box_id;
            elm.transf_box_id = element.transf_box_id;
            elm.qte = element.qte;

            boxs.push(elm);
            //
          }
        }
        v = {
          mvmdetail: [
            {
              qte: parseFloat(this.editedItem.qte),
              mvm_id: this.item.mvm_id,
              article_id: this.editedItem.article_id,
              mvm_from: this.editedItem.mvm_from,
              depot_id: this.editedItem.depot_id,
              pu: this.editedItem.pmp,
              fk_unit: this.editedItem.fk_unit,
              comment: this.editedItem.comment,
              write_uid: this.$store.state.me.id,
            },
          ],
          id: this.editedItem.id > 0 ? this.editedItem.id : null,
          mvm_type: 5,
          products_list: products_list.length > 0 ? products_list : null,
          pl: pl.length > 0 ? pl : null,
          boxs: boxs.length > 0 ? boxs : null,
        };

        let r = await this.maj(MVM_DETAIL_UPDATE, v);
        if (r) {
          if (this.editedItem.id > 0)
            this.items.splice(this.item.index, 1, this.editedItem);
          else {
            this.editedItem.id = r.UpdateMvmDetail;
            this.items.push(this.editedItem);
          }
          this.$emit("change", this.editedItem);
          this.$store.dispatch("Changed", true);
          this.$emit("close", this.items);
        } else {
          this.snackbar_text = " erreur d'enregistrement";
          this.snackbar_color = "error";
          this.snackbar = true;
        }
        this.save_disable = false;
      }
    },
  },
};
</script>
